import React from "react";
import { graphql, Link } from "gatsby";
import Page from "../components/Layout/Page";
import { PHOTOSET } from "../routes";
import { format, fromUnixTime } from "date-fns";
import { Query } from "..";
import pageColors from "../data/pageColors";
import pageTitles from "../data/pageTitles";
import PageHeader from "../components/Elements/PageHeader";
import { FluidObject } from "gatsby-image";
import SquareImage from "../components/Elements/SquareImage";

const Photography = ({
    data: {
        allFlickrPhoto: { totalCount: photosTotalCount },
        allFlickrPhotoset: { totalCount, nodes: photosets },
    },
}: {
    data: Query;
}) => (
    <Page title={pageTitles.photography}>
        <PageHeader
            color={pageColors.photography}
            counts={[`${totalCount} photosets`, `${photosTotalCount} photos`]}
        >
            I started doing photography to prepare myself for art school, and
            did a whole bunch of photosets exploring the cities and places
            around me, with a huge focus on texture and color; taking small
            easily missed details and magnifying them.
            <br />
            Things changed when I did a photoset called{" "}
            <a
                href={PHOTOSET("72157617305031800")}
                className={"text-white font-bold"}
            >
                Les Choses Mortes
            </a>{" "}
            about an abandoned house in my hometown. From this point on I had
            discovered urban exploration and never really let go of it
            afterwards.
            <p>
                The latest photosets all depict a different abandoned location
                as a result, as part of a series I called "The Dead Things",
                with a few "mixed" sets in between here and there.
            </p>
        </PageHeader>
        <div
            className={`flex flex-wrap justify-around p-3 md:p-10 bg-white -mx-2 dark:bg-gray-900`}
        >
            {photosets.map((photoset) => (
                <Link
                    to={PHOTOSET(photoset.fields.slug)}
                    className="w-full lg:w-6/12 xl:w-4/12 p-5 lg:p-10 text-center text-gray-800 hover:bg-gray-800 dark-hover:bg-gray-800 dark:text-white hover:text-white transition font-sans w-full rounded"
                    key={photoset.id}
                >
                    <SquareImage
                        className={"max-w-full"}
                        fluid={
                            photoset.image.childImageSharp.fluid as FluidObject
                        }
                    />
                    <h1 className={"mt-5 text-4xl font-hairline"}>
                        {photoset.title}
                    </h1>
                    <p className={"text-gray-500"}>
                        {format(
                            fromUnixTime(Number(photoset.date_create)),
                            "Y",
                        )}
                        , {photoset.photos.length} photos
                    </p>
                </Link>
            ))}
        </div>
    </Page>
);

export const query = graphql`
    {
        allFlickrPhoto {
            totalCount
        }
        allFlickrPhotoset {
            totalCount
            nodes {
                id
                flickrId
                title
                date_create
                fields {
                    slug
                }
                photos {
                    id
                }
                image {
                    childImageSharp {
                        fluid(quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;

export default Photography;
